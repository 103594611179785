// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Workflow.css */
.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:hover {
  background-color: #99ddcc;
}

/* Workflow.css */
.center-pagination .MuiTablePagination-root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
}

.center-pagination .MuiTablePagination-select {
  margin: none;
}

.center-pagination .MuiTablePagination-displayedRows {
  margin: none;
}

.center-pagination .MuiTablePagination-selectLabel p,
.center-pagination .MuiTablePagination-displayedRows p {
  margin: none; /* Remove default margin from p tags */
}

p {
  margin-top: 1rem;
}

.log-icon {
  transition: color 0.3s;
}

.log-icon:hover {
  color: #99ddcc;
}

.custom-data-grid .MuiDataGrid-cell {
  color: black; /* Change text color to black */
}

.custom-data-grid .MuiDataGrid-columnHeader {
  color: black; /* Change header text color to black */
}
`, "",{"version":3,"sources":["webpack://./src/material_ui/Workflow.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;;;EAGE,yBAAyB;AAC3B;;AAEA,iBAAiB;AACjB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,YAAY,EAAE,sCAAsC;AACtD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY,EAAE,+BAA+B;AAC/C;;AAEA;EACE,YAAY,EAAE,sCAAsC;AACtD","sourcesContent":["/* Workflow.css */\r\n.MuiDataGrid-root .MuiDataGrid-columnHeader,\r\n.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,\r\n.MuiDataGrid-root .MuiDataGrid-columnHeader:hover {\r\n  background-color: #99ddcc;\r\n}\r\n\r\n/* Workflow.css */\r\n.center-pagination .MuiTablePagination-root {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: none;\r\n}\r\n\r\n.center-pagination .MuiTablePagination-select {\r\n  margin: none;\r\n}\r\n\r\n.center-pagination .MuiTablePagination-displayedRows {\r\n  margin: none;\r\n}\r\n\r\n.center-pagination .MuiTablePagination-selectLabel p,\r\n.center-pagination .MuiTablePagination-displayedRows p {\r\n  margin: none; /* Remove default margin from p tags */\r\n}\r\n\r\np {\r\n  margin-top: 1rem;\r\n}\r\n\r\n.log-icon {\r\n  transition: color 0.3s;\r\n}\r\n\r\n.log-icon:hover {\r\n  color: #99ddcc;\r\n}\r\n\r\n.custom-data-grid .MuiDataGrid-cell {\r\n  color: black; /* Change text color to black */\r\n}\r\n\r\n.custom-data-grid .MuiDataGrid-columnHeader {\r\n  color: black; /* Change header text color to black */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
