import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface RunWorkflowButtonProps {
  onClick: () => void;
  showReRunText?: boolean; // New prop to conditionally display "Re-Rerun" text
}

const RunWorkflowButton: React.FC<RunWorkflowButtonProps> = ({ onClick, showReRunText  }) => {
  return (
    <Stack direction="row" spacing={0}>
      <Button
        variant="contained"
        startIcon={<PlayArrowIcon />}
        sx={{
          backgroundColor: '#000000',
          '&:hover': {
            backgroundColor: '#99ddcc',
            color: 'black',
          },
        }}
        onClick={onClick}
      >
        {showReRunText ? 'Re-Run' : 'Run'}
      </Button>
    </Stack>
  );
};

export default RunWorkflowButton;
