// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 50%; /* Limiting the width to 50% of the viewport width */
  max-width: 500px; /* Maximum width of 500px */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Relative positioning for child elements */
}

.modal-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.modal-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,UAAU,EAAE,oDAAoD;EAChE,gBAAgB,EAAE,2BAA2B;EAC7C,yCAAyC;EACzC,kBAAkB,EAAE,4CAA4C;AAClE;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".modal-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100vw;\r\n  height: 100vh;\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n  z-index: 1000;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.modal-content {\r\n  background: #fff;\r\n  padding: 30px;\r\n  border-radius: 8px;\r\n  width: 50%; /* Limiting the width to 50% of the viewport width */\r\n  max-width: 500px; /* Maximum width of 500px */\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n  position: relative; /* Relative positioning for child elements */\r\n}\r\n\r\n.modal-header {\r\n  font-size: 24px;\r\n  margin-bottom: 20px;\r\n  color: #333;\r\n}\r\n\r\n.modal-close-button {\r\n  position: absolute;\r\n  right: 20px;\r\n  top: 20px;\r\n  border: none;\r\n  background: transparent;\r\n  font-size: 18px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
