import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import './ScriptingNodeModal.css';

interface ScriptingNodeModalProps {
  onSave: (selectedFile: string | null) => void;
  onClose: () => void;
  open: boolean; // Add open prop
}

interface S3Object {
  Key: string;
  IsFolder: boolean;
}

const ScriptingNodeModal: React.FC<ScriptingNodeModalProps> = ({ onSave, onClose, open }) => {
  const [files, setFiles] = useState<S3Object[]>([]); // List of files from S3
  const [selectedFile, setSelectedFile] = useState<string | null>(null); // Selected file from S3
  const [fileTypeWarning, setFileTypeWarning] = useState<boolean>(false); // Flag for incorrect file type warning

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
    region: process.env.REACT_APP_AWS_REGION!,
  });

  const s3 = new AWS.S3();

  const listFiles = async (prefix?: string) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
      Prefix: prefix ? prefix : undefined,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const objects = data.Contents?.map((item) => ({ Key: item.Key!, IsFolder: item.Key!.endsWith('/') })) || [];
      setFiles(objects);
    } catch (error) {
      console.error('Error listing files: ', error);
    }
  };

  useEffect(() => {
    if (open) {
      listFiles();
    }
  }, [open]);

  const handleFileSelect = async (fileName: string, isFolder: boolean) => {
    if (!isFolder) {
      setSelectedFile(fileName);
      setFileTypeWarning(!(fileName.endsWith('.r') || fileName.endsWith('.py'))); // Set warning flag
    }
  };

  const handleSave = () => {
    onSave(selectedFile);
    onClose();
  };

  if (!open) return null; // Return null if not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p className={`modal-txt ${fileTypeWarning ? 'file-type-warning' : ''}`}>
          {fileTypeWarning ? 'Incorrect file type selected' : selectedFile ? `Selected file: ${selectedFile}` : 'No file selected'}
        </p>
        <div className="file-table-container">
          <table className="file-table">
            <tbody>
              {files.map((file, index) => (
                <tr
                  key={index}
                  onClick={() => handleFileSelect(file.Key, file.IsFolder)}
                  className={selectedFile === file.Key ? 'selected' : ''}
                >
                  <td className={file.IsFolder ? 'folder-text' : 'file-text'}>{file.Key}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="upload-file-btn" onClick={handleSave} disabled={!selectedFile || fileTypeWarning}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default ScriptingNodeModal;
