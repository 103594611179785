import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';

export default function DeleteButton() {
  return (
    <Stack direction="row" spacing={0}>
      <Button variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: 'black', '&amp;:hover': { backgroundColor: '#99ddcc', color: 'black', } }}>
        New Workflow
      </Button>
    </Stack>
  );
}
