// src/components/home/home.component.tsx
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import NewWorkflowButton from '../../material_ui/NewWorkflowButton';
import Workflow_Table from '../../material_ui/Workflow_Table';
import UserService from '../../services/user.service';
import { withRouter } from './withRouter'; // Ensure this path is correct

type Props = {
  navigate: any;
  location: any;
  params: any;
  match: any;
};

type State = {
  content: string;
};

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: ''
    };
  }

  componentDidMount() {
    const userLoggedIn = UserService.isUserLoggedIn();
    if (!userLoggedIn) {
      this.props.navigate('/login');
    } else {
      UserService.getPublicContent().then(
        response => {
          this.setState({
            content: response.data
          });
        },
        error => {
          this.setState({
            content:
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          });
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className="new-workflow-btn-container">
          <Link to="/workflowv1">
            <NewWorkflowButton />
          </Link>
        </div>
        <Workflow_Table />
      </div>
    );
  }
}

export default withRouter(Home);
