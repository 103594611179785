// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notebook-container {
  margin: 10px;
  padding: 10px;
}

.cell {
  margin-bottom: 20px;
}

.cell.code {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 20px;
}

.save-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/styles/NotebookEditor.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".notebook-container {\r\n  margin: 10px;\r\n  padding: 10px;\r\n}\r\n\r\n.cell {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.cell.code {\r\n  border: 1px solid #ccc;\r\n  padding: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\ntextarea {\r\n  width: 100%;\r\n  height: 100px;\r\n  padding: 10px;\r\n  margin-top: 5px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 5px;\r\n  resize: vertical;\r\n}\r\n\r\n.loading {\r\n  text-align: center;\r\n  padding: 20px;\r\n  font-size: 20px;\r\n}\r\n\r\n.save-button {\r\n  background-color: #4caf50;\r\n  color: white;\r\n  padding: 10px 20px;\r\n  margin: 10px 0;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.save-button:hover {\r\n  background-color: #45a049;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
