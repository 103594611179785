import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import './DataUploadModal.css'; // Reuse the CSS for modal styling

interface DataUploadModalProps {
  onSave: (selectedFiles: string[]) => void;
  onClose: () => void;
  open: boolean; // Add open prop
}

interface S3Object {
  Key: string;
  IsFolder: boolean;
}

const DataUploadModal: React.FC<DataUploadModalProps> = ({ onSave, onClose, open }) => {
  const [files, setFiles] = useState<S3Object[]>([]); // List of files from S3
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]); // Selected files from S3

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
    region: process.env.REACT_APP_AWS_REGION!,
  });

  const s3 = new AWS.S3();

  const listFiles = async (prefix?: string) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
      Prefix: prefix ? prefix : undefined,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const objects = data.Contents?.map((item) => ({ Key: item.Key!, IsFolder: item.Key!.endsWith('/') })) || [];
      setFiles(objects);
    } catch (error) {
      console.error('Error listing files: ', error);
    }
  };

  useEffect(() => {
    if (open) {
      listFiles();
    }
  }, [open]);

  const handleFileSelect = async (fileName: string, isFolder: boolean) => {
    if (isFolder) {
      // If the clicked item is a folder, list its contents
      await listFiles(fileName);
    } else {
      // If it's a file, toggle selection
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.includes(fileName)
          ? prevSelectedFiles.filter((file) => file !== fileName)
          : [...prevSelectedFiles, fileName]
      );
    }
  };

  const handleSave = () => {
    onSave(selectedFiles);
    onClose();
  };

  if (!open) return null; // Return null if not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p className="modal-txt">
          {selectedFiles.length > 0 ? `Selected files: ${selectedFiles.join(', ')}` : 'No files selected'}
        </p>
        <div className="file-table-container">
          <table className="file-table">
            <tbody>
              {files.map((file, index) => (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => handleFileSelect(file.Key, file.IsFolder)}
                    className={selectedFiles.includes(file.Key) ? 'selected' : ''}
                  >
                    <td className={file.IsFolder ? 'folder-text' : 'file-text'}>{file.Key}</td>
                  </tr>
                  {file.IsFolder && (
                    <tr>
                      <td colSpan={1}>
                        <table className="sub-file-table">
                          <tbody>
                            <tr>
                              <td>Subfile 1</td>
                            </tr>
                            <tr>
                              <td>Subfile 2</td>
                            </tr>
                            {/* Add more sub-files if needed */}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <button className="upload-file-btn" onClick={handleSave}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default DataUploadModal;
