// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.del-label-container {
    width: 80%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
}

.option-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.option-group input[type="checkbox"] {
    margin-right: 8px;
}

.download-btn {
    padding: 8px 12px;
    margin-top: 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s;
}

.download-btn:hover {
    background-color: #0056b3;
}

.download-section {
    margin-top: 20px;
}

.download-section a {
    text-decoration: none;
    color: #007bff;
    margin-right: 8px;
}

.download-section button {
    padding: 6px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #dc3545;
    color: white;
    transition: background-color 0.2s;
}

.download-section button:hover {
    background-color: #c82333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/DelLabel.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,8BAA8B;IAC9B,wCAAwC;IACxC,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".del-label-container {\r\n    width: 80%;\r\n    margin: 20px auto;\r\n    font-family: Arial, sans-serif;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.option-group {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 12px;\r\n}\r\n\r\n.option-group input[type=\"checkbox\"] {\r\n    margin-right: 8px;\r\n}\r\n\r\n.download-btn {\r\n    padding: 8px 12px;\r\n    margin-top: 12px;\r\n    font-size: 14px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    background-color: #007bff;\r\n    color: white;\r\n    transition: background-color 0.2s;\r\n}\r\n\r\n.download-btn:hover {\r\n    background-color: #0056b3;\r\n}\r\n\r\n.download-section {\r\n    margin-top: 20px;\r\n}\r\n\r\n.download-section a {\r\n    text-decoration: none;\r\n    color: #007bff;\r\n    margin-right: 8px;\r\n}\r\n\r\n.download-section button {\r\n    padding: 6px 10px;\r\n    font-size: 14px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    background-color: #dc3545;\r\n    color: white;\r\n    transition: background-color 0.2s;\r\n}\r\n\r\n.download-section button:hover {\r\n    background-color: #c82333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
