(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@babel/runtime-corejs3/helpers/defineProperty"), require("@babel/runtime-corejs3/helpers/classPrivateFieldSet"), require("@babel/runtime-corejs3/helpers/classPrivateFieldGet"), require("@babel/runtime-corejs3/core-js-stable/object/keys"), require("@babel/runtime-corejs3/core-js-stable/array/is-array"), require("@babel/runtime-corejs3/core-js-stable/instance/map"), require("@babel/runtime-corejs3/core-js-stable/instance/keys"), require("@babel/runtime-corejs3/core-js-stable/instance/reduce"), require("@babel/runtime-corejs3/core-js-stable/object/entries"), require("@babel/runtime-corejs3/core-js-stable/instance/concat"), require("@babel/runtime-corejs3/core-js-stable/weak-map"), require("@babel/runtime-corejs3/core-js-stable/weak-set"), require("@babel/runtime-corejs3/core-js-stable/instance/for-each"), require("@babel/runtime-corejs3/core-js-stable/promise"), require("@babel/runtime-corejs3/core-js-stable/set-timeout"), require("neo4j-driver"), require("neo4j-driver-core"), require("vis-network/standalone"), require("deepmerge"), require("@babel/runtime-corejs3/core-js-stable/symbol"));
	else if(typeof define === 'function' && define.amd)
		define(["@babel/runtime-corejs3/helpers/defineProperty", "@babel/runtime-corejs3/helpers/classPrivateFieldSet", "@babel/runtime-corejs3/helpers/classPrivateFieldGet", "@babel/runtime-corejs3/core-js-stable/object/keys", "@babel/runtime-corejs3/core-js-stable/array/is-array", "@babel/runtime-corejs3/core-js-stable/instance/map", "@babel/runtime-corejs3/core-js-stable/instance/keys", "@babel/runtime-corejs3/core-js-stable/instance/reduce", "@babel/runtime-corejs3/core-js-stable/object/entries", "@babel/runtime-corejs3/core-js-stable/instance/concat", "@babel/runtime-corejs3/core-js-stable/weak-map", "@babel/runtime-corejs3/core-js-stable/weak-set", "@babel/runtime-corejs3/core-js-stable/instance/for-each", "@babel/runtime-corejs3/core-js-stable/promise", "@babel/runtime-corejs3/core-js-stable/set-timeout", "neo4j-driver", "neo4j-driver-core", "vis-network/standalone", "deepmerge", "@babel/runtime-corejs3/core-js-stable/symbol"], factory);
	else if(typeof exports === 'object')
		exports["NeoVis"] = factory(require("@babel/runtime-corejs3/helpers/defineProperty"), require("@babel/runtime-corejs3/helpers/classPrivateFieldSet"), require("@babel/runtime-corejs3/helpers/classPrivateFieldGet"), require("@babel/runtime-corejs3/core-js-stable/object/keys"), require("@babel/runtime-corejs3/core-js-stable/array/is-array"), require("@babel/runtime-corejs3/core-js-stable/instance/map"), require("@babel/runtime-corejs3/core-js-stable/instance/keys"), require("@babel/runtime-corejs3/core-js-stable/instance/reduce"), require("@babel/runtime-corejs3/core-js-stable/object/entries"), require("@babel/runtime-corejs3/core-js-stable/instance/concat"), require("@babel/runtime-corejs3/core-js-stable/weak-map"), require("@babel/runtime-corejs3/core-js-stable/weak-set"), require("@babel/runtime-corejs3/core-js-stable/instance/for-each"), require("@babel/runtime-corejs3/core-js-stable/promise"), require("@babel/runtime-corejs3/core-js-stable/set-timeout"), require("neo4j-driver"), require("neo4j-driver-core"), require("vis-network/standalone"), require("deepmerge"), require("@babel/runtime-corejs3/core-js-stable/symbol"));
	else
		root["NeoVis"] = factory(root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined]);
})(self, (__WEBPACK_EXTERNAL_MODULE__783__, __WEBPACK_EXTERNAL_MODULE__642__, __WEBPACK_EXTERNAL_MODULE__779__, __WEBPACK_EXTERNAL_MODULE__995__, __WEBPACK_EXTERNAL_MODULE__852__, __WEBPACK_EXTERNAL_MODULE__820__, __WEBPACK_EXTERNAL_MODULE__270__, __WEBPACK_EXTERNAL_MODULE__83__, __WEBPACK_EXTERNAL_MODULE__209__, __WEBPACK_EXTERNAL_MODULE__848__, __WEBPACK_EXTERNAL_MODULE__317__, __WEBPACK_EXTERNAL_MODULE__483__, __WEBPACK_EXTERNAL_MODULE__349__, __WEBPACK_EXTERNAL_MODULE__723__, __WEBPACK_EXTERNAL_MODULE__519__, __WEBPACK_EXTERNAL_MODULE__949__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__365__, __WEBPACK_EXTERNAL_MODULE__613__, __WEBPACK_EXTERNAL_MODULE__581__) => {
return 