// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-workflow-btn-container {
    display: flex;
    width: 85%;
    justify-content: flex-end;
    margin: 2rem auto;
  }

.home-header {
    width: 90%;
    margin: 2rem auto 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/home/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,yBAAyB;IACzB,iBAAiB;EACnB;;AAEF;IACI,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":[".new-workflow-btn-container {\r\n    display: flex;\r\n    width: 85%;\r\n    justify-content: flex-end;\r\n    margin: 2rem auto;\r\n  }\r\n\r\n.home-header {\r\n    width: 90%;\r\n    margin: 2rem auto 0 auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
