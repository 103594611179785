// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 30px 0;
}

button {
    padding: 8px 12px;
    margin: 5px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

button:hover {
    background-color: #575757;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table, th, td {
    border: 1px solid #c5c5c5;
}

th, td {
    padding: 10px 15px;
    text-align: left;
}

th {
    background-color: #e9e9e9;
    font-weight: bold;
}

input[type="text"] {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    width: 100%;
}

tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EditRel.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container {\r\n    width: 80%;\r\n    margin: 0 auto;\r\n    font-family: Arial, sans-serif;\r\n    padding: 30px 0;\r\n}\r\n\r\nbutton {\r\n    padding: 8px 12px;\r\n    margin: 5px;\r\n    font-size: 14px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.2s;\r\n}\r\n\r\nbutton:hover {\r\n    background-color: #575757;\r\n}\r\n\r\nbutton:disabled {\r\n    background-color: #cccccc;\r\n    cursor: not-allowed;\r\n}\r\n\r\ntable {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-top: 20px;\r\n}\r\n\r\ntable, th, td {\r\n    border: 1px solid #c5c5c5;\r\n}\r\n\r\nth, td {\r\n    padding: 10px 15px;\r\n    text-align: left;\r\n}\r\n\r\nth {\r\n    background-color: #e9e9e9;\r\n    font-weight: bold;\r\n}\r\n\r\ninput[type=\"text\"] {\r\n    padding: 5px;\r\n    font-size: 14px;\r\n    border: 1px solid #c5c5c5;\r\n    border-radius: 4px;\r\n    width: 100%;\r\n}\r\n\r\ntbody tr:nth-child(even) {\r\n    background-color: #f2f2f2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
