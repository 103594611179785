import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { setChonkyDefaults, ChonkyActions, ChonkyFileActionData, FileActionHandler, FileArray, FileData, FileAction } from 'chonky';
import { FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar } from 'chonky';
import AWS from 'aws-sdk';
import { makeStyles } from '@material-ui/core/styles';

// Set up AWS SDK with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  region: process.env.REACT_APP_AWS_REGION!,
});

const s3 = new AWS.S3();

// Define styles as an object
const useStyles = makeStyles({
  fileBrowserContainer: {
    height: '100vh',
	width: '100%',
  },
});

const MainComponent: React.FC = () => {
  const classes = useStyles();
  const [files, setFiles] = useState<FileArray>([]);
  const [folderChain, setFolderChain] = useState<FileArray>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);

  const fetchFilesAndFolders = async () => {
    try {
      const s3Response = await s3.listObjectsV2({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Prefix: '',
        Delimiter: '/',
      }).promise();

      console.log('Fetched files and folders:', s3Response);

      const fetchedFolders: FileArray = s3Response.CommonPrefixes?.map(folder => ({
        id: folder.Prefix!,
        name: folder.Prefix!.split('/').slice(-2)[0],
        isDir: true,
      })) || [];

      const fetchedFiles: FileArray = s3Response.Contents?.map(file => ({
        id: file.Key!,
        name: file.Key!.split('/').pop()!,
        thumbnailUrl: `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${file.Key}`, // Assuming public access
      })) || [];

      setFiles([...fetchedFolders, ...fetchedFiles]);
      setFolderChain([{ id: 'root', name: 'Root', isDir: true }]); // Assuming you start from root
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileAction: FileActionHandler = useCallback(async (data: ChonkyFileActionData) => {
    console.log('File action triggered:', data);

    if (data.id === ChonkyActions.DeleteFiles.id) {
      console.log('Delete action detected');
      if (data.state.selectedFiles) {
        const file = data.state.selectedFiles[0];
        console.log('File to be deleted:', file);
        if (file) {
          try {
            await s3.deleteObject({
              Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
              Key: file.id,
            }).promise();

            console.log('File deleted successfully:', file.id);

            // Refresh file list after deletion
            fetchFilesAndFolders();
          } catch (error) {
            console.error('Error deleting file:', error);
          }
        }
      } else {
        console.log('No files in selectedFiles:', data.state.selectedFiles);
      }
    } else if (data.id === ChonkyActions.UploadFiles.id) {
      console.log('Upload action detected');
      document.getElementById('fileInput')?.click();
    }
  }, []);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Key: file.name,
        Body: file,
      };

      try {
        const uploadResponse = await s3.upload(params).promise();
        console.log('File uploaded successfully:', uploadResponse);

        // Refresh file list after upload
        fetchFilesAndFolders();
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleSelectionChange = (fileAction: FileAction, selectedFiles: FileData[]) => {
    console.log('Selection changed:', selectedFiles);
    setSelectedFiles(selectedFiles);
  };

  useEffect(() => {
    fetchFilesAndFolders();
  }, []);

  return (
    <div className={classes.fileBrowserContainer}>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
      <DndProvider backend={HTML5Backend}>
        <FileBrowser
          files={files}
          folderChain={folderChain}
          fileActions={[ChonkyActions.UploadFiles, ChonkyActions.DownloadFiles, ChonkyActions.DeleteFiles]}
          onFileAction={handleFileAction}
          onFileActionStateChange={handleSelectionChange}
        >
          <FileNavbar />
          <FileToolbar />
          <FileList />
          <FileContextMenu />
        </FileBrowser>
      </DndProvider>
    </div>
  );
};

export default MainComponent;