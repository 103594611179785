import * as React from 'react';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

interface RefreshWorkflowButtonProps {
  onClick: () => void;
}

const RefreshWorkflowButton: React.FC<RefreshWorkflowButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      startIcon={<RefreshIcon />}
      onClick={onClick}
      sx={{
        backgroundColor: '#99ddcc',
        color: 'black',
        '&:hover': {
          backgroundColor: '#77c6a8',
        },
      }}
    >
      Refresh
    </Button>
  );
};

export default RefreshWorkflowButton;
