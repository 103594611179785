// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

/* Light Variation Gradient */
.appBackground {
  background: linear-gradient(to bottom right, #a3aefc, #fd9db0);
  min-height: 100vh;
  padding: 2rem;
}

/* Workflow List Styles */
h2 {
  margin-bottom: 1rem;
  color: #333;
}

.TableContainer {
  margin-top: 1rem;
}

.TableCell {
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.Button {
  margin-right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/WorkflowList.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,gCAAgC;EAChC,SAAS;EACT,UAAU;AACZ;;AAEA,6BAA6B;AAC7B;EACE,8DAA8D;EAC9D,iBAAiB;EACjB,aAAa;AACf;;AAEA,yBAAyB;AACzB;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["/* Global Styles */\r\nbody {\r\n  font-family: \"Arial\", sans-serif;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n/* Light Variation Gradient */\r\n.appBackground {\r\n  background: linear-gradient(to bottom right, #a3aefc, #fd9db0);\r\n  min-height: 100vh;\r\n  padding: 2rem;\r\n}\r\n\r\n/* Workflow List Styles */\r\nh2 {\r\n  margin-bottom: 1rem;\r\n  color: #333;\r\n}\r\n\r\n.TableContainer {\r\n  margin-top: 1rem;\r\n}\r\n\r\n.TableCell {\r\n  padding: 1rem;\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.Button {\r\n  margin-right: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
